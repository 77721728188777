<template>
  <div class="content-box-item-s">
    <el-tabs
      v-model="activeName"
      type="border-card"
      class="tabs-box"
      @tab-click="handleClick"
    >
      <template v-for="(item, index) in towerDetails">
        <el-tab-pane :key="index" :label="item.name" :name="`${index}`">
          <el-row :gutter="20">
            <!-- 左 -->
            <el-col :span="11" class="border-right">
              <template
                v-if="item.latestDatas && item.latestDatas.length !== 0"
              >
                <template v-for="(ite, ii) in item.latestDatas">
                  <div :key="ii" class="f">
                    <div class="p-r animated zoomIn">
                      <el-image
                        class="lifter-image"
                        :src="require('../../../assets/image/tower-img.png')"
                      />
                      <div
                        class="t-s-m p-a c-p"
                        @click="onPlayTowerVideo(index)"
                      >
                        查看塔钩监控>
                      </div>
                      <div class="f f-c f-j-c p-a main-box">
                        <div class="">
                          <el-image
                            class="lifter-avter"
                            :src="ite.mugshotOfDriver"
                            :fit="'cover'"
                          >
                            <div slot="error" class="lifter-avter">
                              <el-image
                                class="lifter-avter"
                                :src="
                                  require('../../../assets/image/td-error.png')
                                "
                                :fit="'cover'"
                              />
                            </div>
                          </el-image>
                        </div>
                        <div class="t-s-g">{{ ite.operName || "-" }}</div>
                        <div class="t-s-g">
                          {{ ite.corpNameOfDriver || "-" }}
                        </div>
                      </div>
                    </div>
                    <div class="lifter-details f f-c f-j-c">
                      <el-row :gutter="20">
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/tower/zl.png')
                              "
                            />
                            <div class="li-text c-fff">载 重</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ ite.weight }}<span class="sup-text">kg</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/tower/fd.png')
                              "
                            />
                            <div class="li-text c-fff">幅 度</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ ite.rRange }}<span class="sup-text">m</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/tower/gd.png')
                              "
                            />
                            <div class="li-text c-fff">高 度</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ ite.height }}<span class="sup-text">m</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box mt-20 f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/tower/lj.png')
                              "
                            />
                            <div class="li-text c-fff">力 矩</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ ite.moment }}<span class="sup-text">%</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box mt-20 f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/tower/qj.png')
                              "
                            />
                            <div class="li-text c-fff">倾 角</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ ite.obliguity
                              }}<span class="sup-text">度</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box mt-20 f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/tower/fs.png')
                              "
                            />
                            <div class="li-text c-fff">风 速</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ ite.windSpeed
                              }}<span class="sup-text">m/s</span>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="box-not-data f f-a-c f-j-c animated zoomIn">
                  暂无塔吊详情实时数据...
                </div>
              </template>
            </el-col>
            <!-- 中 -->
            <el-col :span="7" class="border-right">
              <loading
                v-if="antiCollisionsLoading"
                :height="465"
                :opacity="0"
                :append="true"
              />
              <template v-else>
                <div
                  class="center-box"
                  v-show="towerCData && towerCData.length > 0"
                >
                  <!-- 防碰撞canvas绘制图形组件 -->
                  <tower-canvas
                    v-if="`${index}` == activeName"
                    :tower-c-data="towerCData"
                  />
                </div>
                <div
                  v-show="towerCData.length === 0"
                  class="box-not-data f f-a-c f-j-c animated zoomIn"
                >
                  暂无塔吊防碰撞数据...
                </div>
              </template>
            </el-col>
            <!-- 右 -->
            <el-col :span="6">
              <loading
                v-if="lineLoading"
                :height="465"
                :opacity="0"
                :append="true"
              />
              <template v-else>
                <div class="box-item p-r">
                  <template v-if="chartDataWHasVal">
                    <dynamic-data
                      v-if="`${index}` == activeName"
                      :title="'载重记录(单位：Kg)'"
                      :chart-data="chartDataW"
                    />
                  </template>
                  <template v-else>
                    <div class="empty animated zoomIn">暂无塔吊载重记录</div>
                  </template>
                </div>
                <div class="box-item mt-10 p-r">
                  <template v-if="chartDataHHasVal">
                    <dynamic-data
                      v-if="`${index}` == activeName"
                      :title="'高度记录(单位：m)'"
                      :chart-data="chartDataH"
                    />
                  </template>
                  <template v-else>
                    <div class="empty animated zoomIn">暂无塔吊高度记录</div>
                  </template>
                </div>
              </template>
            </el-col>
          </el-row>
        </el-tab-pane>
      </template>
    </el-tabs>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      width="40%"
    >
      <monitor :box-height="400" :item="monitorItem" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import monitor from "@/components/monitor";
import Loading from "@/components/loading";
import { getNowTime, getBeforTime } from "@/utils/index";
import {
  getAllTower,
  getWeightAndHeightStat,
  getAntiCollisionsData,
} from "@/api/device";
import towerCanvas from "@/components/tower-canvas/index";
import dynamicData from "@/components/echarts/dynamic-data";
const timeRange = 86400000; //3600000;  // 86400000 多长时间内（毫秒）
export default {
  components: {
    dynamicData,
    towerCanvas,
    monitor,
    Loading,
  },
  data() {
    return {
      chartDataWHasVal: false,
      chartDataW: {},
      chartDataHHasVal: false,
      chartDataH: {},
      dialogTitle: undefined,
      dialogVisible: false,
      towerDetails: [], // 塔吊详情数据
      monitorItem: {}, // 塔钩监控数据
      towerCData: [], // 防碰撞数据
      activeName: "0",
      antiCollisionsLoading: false,
      lineLoading: false,
      params: {
        sn: "", // sn
        beginTime: getBeforTime(timeRange), //getBeforTime(86400000), // 开始时间
        endTime: getNowTime(), // 结束时间
      },
    };
  },
  created() {
    // 获取塔吊详情数据
    this.getAllTowerData();
  },
  methods: {
    /**
     * 标签页切换事件
     */
    handleClick(tab, event) {
      const i = Number(tab.index);
      // 获取折线图数据
      // this.params.beginTime = "2021-05-20 7:00:00";
      // this.params.endTime = "2021-06-02 14:00:00";
      this.params.beginTime = getBeforTime(timeRange);
      this.params.endTime = getNowTime();
      if (!this.towerDetails[i].latestDatas) return;
      this.params.sn = this.towerDetails[i].latestDatas[0].sn;
      this.getTowerCollision();
      this.getGraphDataList();
    },
    /**
     * 获取塔吊数据
     */
    getAllTowerData() {
      getAllTower().then((res) => {
        const { data } = res;
        this.towerDetails =
          data.items && data.items.length > 0 ? data.items : [];
        // 获取折线图数据
        this.params.sn = this.towerDetails[0].latestDatas[0].sn;
        this.getTowerCollision();
        this.getGraphDataList();
      });
    },
    /**
     * 获取塔吊折线图数据
     */
    getGraphDataList() {
      this.chartDataWHasVal = false;
      this.chartDataHHasVal = false;
      this.lineLoading = true;
      getWeightAndHeightStat(this.params).then((res) => {
        const { data } = res;
        this.chartDataWHasVal =
          data.items &&
          data.items.length > 0 &&
          Object.keys(data.items[0].weight).length > 0;
        this.chartDataHHasVal =
          data.items &&
          data.items.length > 0 &&
          Object.keys(data.items[0].height).length > 0;
        this.lineLoading = false;
        this.$nextTick(() => {
          this.chartDataW =
            data.items && data.items.length > 0 ? data.items[0].weight : {};
          this.chartDataH =
            data.items && data.items.length > 0 ? data.items[0].height : {};
          // console.log("获取出来图表数据-载重", this.chartDataW);
          // console.log("获取出来图表数据-高度", this.chartDataH);
        });
      });
    },
    /**
     * 获取塔吊防碰撞数据
     *  ctx:canvas画布,x:圆心点X,y圆心Y,color:颜色,r:半径,ar:前臂,br:后臂,range:幅度,angle:角度,title:标题,fillcolor:填充色
     */
    getTowerCollision() {
      this.towerCData = [];
      this.antiCollisionsLoading = true;
      getAntiCollisionsData(this.params.sn).then((res) => {
        const { data } = res;
        this.$nextTick(() => {
          setTimeout(() => {
            this.antiCollisionsLoading = false;
            this.towerCData =
              data.items && data.items.length > 0
                ? data.items.map((e) => {
                    return {
                      id: e.id,
                      sn: e.sn,
                      x: e.x,
                      y: e.y,
                      status: e.status,
                      r: e.r,
                      ar: e.ar,
                      br: e.br,
                      range: e.range,
                      angle: e.angle,
                      title: e.name,
                    };
                  })
                : [];
          }, 500);
        });
      });
    },
    /**
     * 查看塔钩监控事件
     */
    onPlayTowerVideo(index) {
      if (this.towerDetails[index].latestDatas[0].videoDevices === null) {
        return this.$message({
          message: "暂无塔钩监控相关信息",
          duration: 2000,
        });
      }
      const videos = this.towerDetails[index].latestDatas[0].videoDevices[0];
      if (videos.deviceType == 3 && videos.position == 3) {
        this.monitorItem = videos;
        this.dialogTitle = "塔钩实时监控";
        this.dialogVisible = true;
      } else {
        this.$message({ message: "暂无塔钩监控相关信息", duration: 2000 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  // background: rgba(4, 32, 89, 0.92) !important;
  background: rgba(13, 68, 119, 0.92) !important;
}
::v-deep .el-dialog__title {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-icon-close {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-button {
  border: rgba(4, 32, 89, 0) !important;
  background: rgba(4, 32, 89, 0) !important;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #41a5d8 !important;
}
::v-deep .el-dialog__body {
  // background: rgba(4, 32, 89, 0.92) !important;
  background: rgba(13, 68, 119, 0.92) !important;
}
::v-deep .el-dialog__footer {
  // background: rgba(4, 32, 89, 0.92) !important;
  background: rgba(13, 68, 119, 0.92) !important;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #fff;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: rgba(0, 255, 255, 1) !important;
  border: 1px solid rgba(0, 255, 255, 0.6);
  background: rgba(0, 255, 255, 0.2);
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.5) 1%,
    rgba(0, 255, 255, 0.2) 50%,
    rgba(0, 255, 255, 0) 50%
  );
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: rgba(0, 255, 255, 1) !important;
}
::v-deep .el-tabs__nav-scroll {
  background: rgba(15, 80, 133, 1);
  padding: 5px;
}
.content-box-item-s {
  padding: 4px;
}
.tabs-box {
  width: 100%;
  height: 545px;
  background: rgba(15, 80, 133, 0.8);
  border: none;
}

.t-s {
  font-family: "微软雅黑";
  font-size: 16px;
  color: #fff !important;
}
.t-s-m {
  font-family: "微软雅黑";
  font-size: 12px;
  color: #00a0e1;
  right: 100px;
  top: 130px;
  &:hover {
    color: rgba(0, 255, 255, 1) !important;
  }
}
.main-box {
  width: 180px;
  top: 200px;
  right: 0px;
}
.t-s-g {
  font-family: "微软雅黑";
  font-size: 14px;
  color: #c7c9d4 !important;
  // margin-bottom: 6px;
}
.t-s-t {
  font-family: "微软雅黑";
  font-size: 14px;
  color: #fff !important;
}
.lifter-image {
  width: 389px;
  height: 462px;
}
.lifter-avter {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.lifter-details {
  width: 100%;
  padding: 10px;
  background: rgba(153, 153, 153, 0.05);
  margin-left: 10px;
  .liter-box {
    border: 1px solid #1d758f;
    background: rgba(11, 38, 92, 0.04);
    box-shadow: 0 0 30px #1c5e77 inset;
    padding: 5px;
    height: 185px;
  }
  .images-lifer {
    width: 50px;
    height: 50px;
  }
  .li-text {
    font-size: 20px;
    font-family: "微软雅黑";
    font-weight: bold;
  }
  .sup-text {
    font-size: 10px !important;
    font-family: "微软雅黑";
    color: #999e88;
    margin-left: 5px;
  }
}
.border-right {
  border-right: 1px solid #999 !important;
}
.box-item {
  height: 226px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  background: rgba(0, 255, 255, 0.05);
}
.center-box {
  width: 100%;
  height: 465px;
}
.box-not-data {
  height: 465px;
  font-size: 18px;
  text-align: center;
  color: #ffffffc7;
}
</style>
