
class Ball {
  constructor(ctx, x, y, color, r, ar, br, range, angle, title, fillcolor, realRadius) {
    this.ctx = ctx// canvas画布
    this.x = x // 圆心点X
    this.y = y // 圆心Y
    this.color = color // 颜色
    this.r = r // 半径
    this.ar = ar // 前臂
    this.br = br // 后壁
    this.range = range // 幅度
    this.angle = angle // 角度
    this.title = title // 标题
    this.fillcolor = fillcolor // 填充色
    this.realRadius = realRadius || r // 真实半径
  }
  // 绘制塔吊
  render() {
    const ctx = this.ctx
    ctx.globalAlpha = 0.55
    // 外圆
    ctx.lineWidth = 2
    ctx.strokeStyle = this.color// 表盘
    ctx.beginPath()
    ctx.arc(this.x, this.y, this.r, 0, Math.PI * 360, false)
    // 虚线
    // ctx.setLineDash([10, 5])
    // 填充背景色
    ctx.fillStyle = this.fillcolor
    ctx.fill()
    ctx.closePath()
    ctx.stroke()
    ctx.save()

    // 吊臂直线
    // ctx.strokeStyle = this.color
    ctx.strokeStyle = '#fff'
    ctx.lineWidth = 1.5
    ctx.beginPath()
    ctx.translate(this.x, this.y)
    ctx.rotate((this.angle * Math.PI) / 180)// 设置旋转角度
    ctx.moveTo(0, 0 - this.ar)
    ctx.lineTo(0, this.br)
    ctx.font = `${11}px bold arial`
    ctx.closePath()
    ctx.stroke()

    // 设置颜色
    ctx.beginPath()
    ctx.arc(0, 0, 2, 0, 360, false)
    ctx.strokeStyle = this.color
    // 设置文字颜色
    ctx.fillStyle = '#fff'
    ctx.textAlign = 'right'
    ctx.textBaseline = 'middle'
    ctx.fillText(this.realRadius.toFixed(2) + 'm', 0, (this.br - this.ar) / 2)
    ctx.fill()
    ctx.closePath()
    ctx.stroke()

    // 吊臂中心点
    ctx.beginPath()
    ctx.arc(0, 0, 5, 0, 360, false)
    // ctx.arc(0, (0 - this.range), 2, 0, 360, false);
    ctx.closePath()
    // ctx.strokeStyle = this.color
    // ctx.fillStyle = this.color
    ctx.strokeStyle = '#fff'
    ctx.fillStyle = '#fff'
    ctx.fill()
    ctx.stroke()// 设置笔触样式(秒针已设置)
    // ctx.restore();

    // 吊臂点
    ctx.beginPath()
    // ctx.strokeStyle = this.color
    ctx.strokeStyle = '#fff'
    ctx.arc(0, 0 - this.range, 2, 0, 360, false)
    ctx.closePath()
    // ctx.fillStyle = this.color
    ctx.fillStyle = '#fff'
    ctx.fill()
    ctx.stroke() // 设置笔触样式(秒针已设置)
    ctx.restore()

    // 设置文字样式
    ctx.font = '11px bold arial'
    // 设置文字颜色
    // ctx.fillStyle = this.color;
    ctx.fillStyle = '#fff'
    // 设置水平对齐方式
    ctx.textAlign = 'center'
    // 设置垂直对齐方式
    ctx.textBaseline = 'top'
    // ctx.rotate(0-sec * Math.PI / 180);//设置旋转角度
    ctx.fillText(this.title.length > 4 ? this.title.substring(0, 4) + '...' : this.title, this.x, this.y + 10)
    // ctx.fillText(this.title + "#", this.x + 10, this.y);
    ctx.restore()
  }
}

export function render(ctx, x, y, color, r, ar, br, range, angle, title, fillcolor, realRadius) {
  var ball = new Ball(ctx, x, y, color, r, ar, br, range, angle, title, fillcolor, realRadius)
  ball.render()
}
