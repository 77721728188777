<template>
  <div :style="`width:${width}px;height:${height}px;`">
    <div class="legend">
      <div><i :style="`background:${colors.offline};`" />离线</div>
      <div><i :style="`background:${colors.normal};`" />正常</div>
      <div><i :style="`background:${colors.alarm};`" />报警</div>
    </div>
    <div class="content">
      <canvas
        id="mycanvas"
        class="canvas-box"
        :width="canvasWidth"
        :height="canvasHeight"
        style="object-fit: scale-down"
        @click="onCanvas"
      />
    </div>
  </div>
</template>

<script>
// import { juli } from "@/utils/index";
import { render } from "./mixins/twer-canvas";
export default {
  props: {
    towerCData: {
      type: Array,
      default() {
        return [];
      },
    },
    // 缩放倍数
    scales: {
      type: Number,
      default: 1,
    },
    // 拖动x和y的位置坐标
    dragNum: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
        };
      },
    },
    width: {
      type: Number,
      default: 520,
    },
    height: {
      type: Number,
      default: 467,
    },
    colors: {
      type: Object,
      default: () => {
        return { offline: "#666666", normal: "#4579e3", alarm: "#FF0000" };
      },
    },
  },
  data() {
    return {
      canvasWidth: 0,
      canvasHeight: 0,
      borderWidth: 2,
    };
  },
  watch: {
    towerCData: {
      handler(nval, oval) {
        this.drawing(nval);
      },
      // immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.drawing(this.towerCData);
  },
  methods: {
    /**
     * 根据当前点击的位置，获取到点击的塔吊
     */
    onCanvas(event) {
      // for (let i = 0, len = this.towerCData.length; i < len; i++) {
      //   const item = this.towerCData[i];
      //   const de = juli({
      //     x1: item.x,
      //     y1: item.y,
      //     x2: (event.detail.x - this.dragNum.x) / this.scales,
      //     y2: (event.detail.y - this.dragNum.y) / this.scales,
      //   });
      //   if (item.r >= de) {
      //     if (item.sn === "") return;
      //     this.$store.commit("other/SET_TOWERSN", item.sn);
      //     uni.navigateTo({
      //       url: `/pages/view/equipment-details/equipment-details`,
      //     });
      //     break;
      //   }
      // }
    },
    drawing(items) {
      const position = this.getPosition(items);
      this.canvasWidth = position.width;
      this.canvasHeight = position.height;
      // 绘制
      this.$nextTick(() => {
        // 获取Canvas 画布
        const mycanvas = document.getElementById("mycanvas");
        var ctx = mycanvas.getContext("2d");

        // ctx:画布,x:圆心点X,y圆心Y,color:颜色,r:半径,ar:前臂,br:后臂,range:幅度,angle:角度,title:标题,fillcolor:填充色,realRadius:真实半径
        items.forEach((item) => {
          const color =
            item.status === -1
              ? this.colors.offline
              : item.status === 0
              ? this.colors.normal
              : item.status === 1
              ? this.colors.alarm
              : "";
          render(
            ctx,
            (item.x + position.offsetX) * position.zoom + this.borderWidth,
            (item.y + position.offsetY) * position.zoom + this.borderWidth,
            color,
            item.r * position.zoom,
            item.ar * position.zoom,
            item.br * position.zoom,
            item.range,
            item.angle,
            item.title,
            color,
            item.r
          );
        });
      });
    },
    getPosition(items) {
      let left = this.width,
        top = this.height,
        srcWidth = 0,
        srcHeight = 0,
        width = 0,
        height = 0,
        zoom = 1;
      items.forEach((item) => {
        left = Math.min(left, item.x - item.r);
        top = Math.min(top, item.y - item.r);
        srcWidth = Math.max(srcWidth, item.x + item.r);
        srcHeight = Math.max(srcHeight, item.y + item.r);
      });
      srcWidth = srcWidth - left;
      srcHeight = srcHeight - top;
      const desWidth = this.width - this.borderWidth * 2, // 减除线条宽度的两倍
        desHeight = this.height - 30 - this.borderWidth * 2; // 减除线条宽度的两倍
      if (srcWidth / srcHeight > desWidth / desHeight) {
        zoom = desWidth / srcWidth;
        width = desWidth;
        height = (srcHeight * desWidth) / srcWidth;
      } else {
        zoom = desHeight / srcHeight;
        height = desHeight;
        width = (srcWidth * desHeight) / srcHeight;
      }
      const result = {
        offsetX: -left,
        offsetY: -top,
        width: width + this.borderWidth * 2,
        height: height + this.borderWidth * 2,
        zoom: zoom,
      };
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.canvas-box {
  /* pointer-events: auto !important;
  float: left; */
  width: 100%;
  height: 100%;
}
.legend {
  display: flex;
  height: 30px;
  > div {
    display: flex;
    color: #ffffff;
    margin: 0 5px;
    line-height: 1.5;
    i {
      display: block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      border-radius: 4px;
    }
  }
}
.content {
  width: 100%;
  height: calc(100% - 30px);
}
</style>
