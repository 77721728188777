<template>
  <div ref="myChart" class="charts-box animated zoomIn" />
</template>

<script>
import { timeToSec } from '@/utils/index'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    chartData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  watch: {
    chartData: {
      handler(nval, oval) {
        // if (Object.keys(nval).length === 0) return;
        this.initChart(nval)
      },
      deep: true
    }
  },
  methods: {
    initChart(eVal) {
      // 处理数据
      const xData = Object.keys(eVal)
      const yData = []
      for (const key in eVal) {
        yData.push(eVal[key])
      }

      // 获取元素
      const myChart = this.$echarts.init(this.$refs.myChart)

      // Echarts配置项
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          position: function(pt) {
            return [pt[0], '10%']
          }
        },
        title: {
          left: 'center',
          text: this.title,
          top: 8,
          // left: 38,
          textStyle: {
            fontSize: 14,
            color: '#fff'
          }
        },
        toolbox: {
          show: false,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData,
          axisLabel: {
            color: '#ffffff',
            interval: 0,
            rotate: 0
          }
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
          axisLabel: {
            color: '#ffffff'
            //   interval: 0,
            //   rotate: 10,
          }
        },
        dataZoom: [
          {
            type: 'inside',
            start: 20,
            end: 60
            // start: xData.length === 0 ? 0 : 20,
            // end: xData.length === 0 ? 100 : 60,
          },
          {
            start: 20,
            end: 60,
            showDetail: false
          }
        ],
        series: [
          {
            name: this.title,
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgb(255, 70, 131)'
            },
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(255, 158, 68)'
                },
                {
                  offset: 1,
                  color: 'rgb(255, 70, 131)'
                }
              ])
            },
            data: yData
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.charts-box {
  width: 100%;
  height: 226px;
}
</style>
